const copy = {
    experience: {
        title: "+ de 10 anos de experiência",
    },
    removeFirst: {
        title: "Somos 100% remote-first",
    },
    forShirt: {
        title: "Vestimos a camisa",
    },
}

export default copy